@font-face {
	font-family: 'Roobert';
	src: url('/RoobertFontVRT/RoobertVRT-Light.woff2') format('woff2'),
		url('/RoobertFontVRT/RoobertVRT-Light.woff') format('woff');
	font-weight: 300;
	font-style: normal;
}
@font-face {
	font-family: 'Roobert';
	src: url('/RoobertFontVRT/RoobertVRT-Regular.woff2') format('woff2'),
		url('/RoobertFontVRT/RoobertVRT-Regular.woff') format('woff');
	font-weight: 400;
	font-style: normal;
}
@font-face {
	font-family: 'Roobert';
	src: url('/RoobertFontVRT/RoobertVRT-Medium.woff2') format('woff2'),
		url('/RoobertFontVRT/RoobertVRT-Medium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
}
@font-face {
	font-family: 'Roobert';
	src: url('/RoobertFontVRT/RoobertVRT-SemiBold.woff2') format('woff2'),
		url('/RoobertFontVRT/RoobertVRT-SemiBold.woff') format('woff');
	font-weight: 600;
	font-style: normal;
}
@font-face {
	font-family: 'Roobert';
	src: url('/RoobertFontVRT/RoobertVRT-Bold.woff2') format('woff2'),
		url('/RoobertFontVRT/RoobertVRT-Bold.woff') format('woff');
	font-weight: 700;
	font-style: normal;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
	/*background-color: #eaeaea;*/
}
